import React from 'react'
import { graphql } from 'gatsby'
import { Quote, Image } from '@roar/components'
import { mapperWithFunction } from '../common/utils'
import { Flex } from 'rebass/styled-components'
import { formatImage } from './ImageWrapper'

const QUOTE_MAP = {
  variant: (value) => {
    if (value.type === 'Testimonial' || value.type === 'Arrows') {
      return 'testimonial'
    }
    if (value.type === 'Blockquote') {
      return 'blockquote'
    }

    return 'blockquote'
  },
  headline: ['headline'],
  subtitle: ['subtitleQuote'],
  text: ['content', 'childMarkdownRemark', 'html'],
  cite: ['cite'],
  imagePos: ['imagePos'],
  imageRotation: ['imageRotation'],
  image: (value) => formatImage(value),
  imageAlt: ['image', 'title'],
  citeImage: (value) => formatImage(value, false, 'citeImage'),
  role: ['role'],
  style: ['style'],
  setDimsCallback: ['setDimsCallback'],
  isSlide: ['isSlide'],
  isActiveSlide: ['isActiveSlide'],
  video: ['video'],
}
const STYLE_MAP = {
  citeWidth: ['citeWidth'],
}

const QuoteWrapper = (props) => {
  const {
    variant,
    headline,
    subtitle,
    text,
    image,
    cite,
    citeImage,
    role,
    style,
    imagePos,
    imageRotation,
    setDimsCallback,
    isSlide,
    isActiveSlide,
    video,
  } = mapperWithFunction(props, QUOTE_MAP)

  const { citeWidth } =
    style && style.internal ? mapperWithFunction(JSON.parse(style.internal.content), STYLE_MAP) : '140px'

  return (
    <Flex {...style}>
      <Quote
        variant={variant}
        headline={headline}
        subtitle={subtitle}
        text={text}
        alt={(props.image?.description || props.image?.title) ?? ''}
        image={image}
        imageRotation={imageRotation}
        imagePos={imagePos || 'left'}
        cite={cite}
        citeImage={
          <Image className="QuoteWrapper_Image" {...citeImage} alt={cite} style={{ width: citeWidth || '140px' }} />
        }
        role={role}
        setDimsCallback={setDimsCallback}
        isSlide={isSlide}
        isActiveSlide={isActiveSlide}
        video={video}
      />
    </Flex>
  )
}

export default QuoteWrapper

export const quote = graphql`
  fragment Quote on ContentfulQuote {
    contentful_id
    id
    name
    type
    content {
      content
      childMarkdownRemark {
        html
      }
    }
    headline
    subtitleQuote: subtitle
    image {
      title
      description
      gatsbyImageData(layout: CONSTRAINED, width: 750, quality: 90)
    }
    imagePos
    imageRotation
    cite
    citeImage {
      gatsbyImageData(layout: CONSTRAINED, width: 280, quality: 90)
    }
    role
    style {
      pb
      pt
      pl
      pr
      bg
      internal {
        content
      }
    }
  }
`
